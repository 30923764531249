import React from "react"

const FAQ = () => {
  return (
    <div className="section">
      <div className="sub-container ">
        <div className="flex ">
          <div className="hr-verticle">
            <hr />
          </div>
          <div className="faq">
            <h3 style={{ maxWidth: "100%" }}>
              FAQs - Frequently Asked Questions
            </h3>
            <h6> Q: How do the dental services work? </h6>
            <p>
              A: First, we have to perform a thorough assessment of your teeth,
              jawbone, and gums. We will also look deeper into your family
              medical history and past medical history to know if you are
              eligible for any specific dental procedures that you want done.
              Once you’re cleared, we’ll discuss the specific procedure with you
              and set the date and time for it.
            </p>
            <h6>Q: What to do next?</h6>
            <p>
              A: We will explain how you can prepare for the procedure and what
              to expect before, during, and after. Depending on the procedure,
              you may just need to brush and floss as usual. For procedures like
              surgeries, you may be required to rest for a few days and take
              some medications after the procedure.
            </p>
            <h6>Q: What is included in the services?</h6>
            <p>
              A: Assessment is always included no matter what procedure is done.
              Prophylaxis is also considered a mainstay to ensure a sterile
              environment during the specific procedure. You may check out the
              specific sections for the services for more details.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
