import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./secondSection.styles.scss"

const SecondSection = () => {
  return (
    <>
      <div className="section second-section ">
        <div className="sub-container">
          <div className="flex space-between">
            <div>
              <h1 className="h6">Dentist in La Mesa</h1>
              <h3>Nobody Should Stress Over Dental Visits</h3>
              <p>
                If you are looking for the best dentist in La Mesa, CA for
                general, cosmetic or emergency dentistry procedures, Dinkha
                Dental is ready to serve you with the most up-to-date dental
                expertise and technologies.
              </p>
              <p>
                At Dinkha Dental, patients are guaranteed top-of-the-line dental
                services for the best smiles. We offer the highest quality of
                customer care, maintaining transparency and always ready to
                answer any questions to ensure each of our patients undergo
                their procedures fully informed and comfortable.
              </p>
            </div>
            <div className="image-center" style={{ width: "50%" }}>
              <StaticImage src="../../images/about.png" alt="about" />
            </div>
          </div>
        </div>
        <div className="hr-div">
          <div className="container mt-4">
            <hr style={{ width: "50%", maxWidth: "90%" }} />
          </div>
          <div className="sub-container ">
            <a href="#contact" className="btn dark-golden relative">
              BOOK AN APPOINTMENT
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecondSection
