import React from "react"
import "./banner.styles.scss"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import Header from "../../header/header.component"

const Banner = () => {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "bg.png" }) {
          id
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 100
              webpOptions: { quality: 100 }
            )
          }
        }
      }
    `
  )

  const image = getImage(backgroundImage)
  const bgImg = convertToBgImage(image)
  return (
    <>
      <BackgroundImage Tage="section" {...bgImg} preserveStackingContext>
        <div className="container">
          <Header url="/" />
          <div className="banner">
            <div className="h2">Exceptional Dental Care</div>
            <p>
              Located in La Mesa, CA, we provide a stress free experience for
              your family.
            </p>
            <div className="cta">
              <div className="btn golden"><a href="#contact" style={{color: 'inherit'}}>Appointments</a></div>
              <div className="btn"><a style={{color: 'inherit'}} href="tel:+1 (619) 462 9933">+1 (619) 462 9933</a></div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default Banner
