import * as React from "react"
import "../layout.scss"
import Banner from "../components/homepage/banner/banner.component"
import SecondSection from "../components/homepage/secondSection/secondSection.component.jsx"
import ServicesComponent from "../components/services.components"
import FAQ from "../components/faqSection.component"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondSections from "../components/homepage/secondSection/secondsection.component1.jsx"
//import App from "../components/reviews/reviews.component"
const IndexPage = () => (
  <>
    <Layout>
      <Seo
        title="Dentist La Mesa, CA | Family Dentistry | Implants - Dr. W. Dinkha"
        description="La mesa dentist offering general family dentistry and emergency dental care. Our services also include dental implants & veneers. Flexible hours and payment plans"
      />
      <Banner />
      <SecondSection />
      <ServicesComponent />

      <SecondSections />
      <FAQ />
    </Layout>
  </>
)

export default IndexPage
