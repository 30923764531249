import React from "react"
import "./secondSection.styles.scss"
import william from "../../../images/william-dinkha.svg"
import ada from "../../../images/ada.svg"
import cda from "../../../images/cda.svg"
import sd from "../../../images/sd.svg"
import {Link} from 'gatsby'

const SecondSections = () => {
  return (
    <>
      <div className="section second-section ">
        <div className="sub-container">
          <div className="flex space-between">
            <div>
              <h6>Comprehensive Excellence</h6>
              <h3>Our Doctor</h3>
              <p>
                Dr. Dinkha is motivated and determined to apply his vast
                experience in dentistry to provide the highest quality of dental
                care. After graduating from Christian High School, he attended
                San Diego State University. After which, he went on to study
                dentistry at one of the most prestigious East Coast dental
                schools, Tufts University.
              </p>
              <p>
                Dr. Dinkha graduated from Tufts in 1994 and eagerly began his
                career. He, now, has over 26 years of experience as a dentist.
                Dr. Dinkha’s family-run dental office, Dinkha Dental in La Mesa,
                has been serving the San Diego community for over 17 years, and
                Dr. Dinkha hopes you truly feel welcomed and cared for at Dinkha
                Dental.
              </p>
            </div>
            <div className="image-center">
              <img src={william} alt="william-dinkha" />
              <p className="bold-p center" style={{ color: "black" }}>
                Dr William Dinkha
              </p>
            </div>
          </div>
        </div>
        <div className="hr-div">
          <div className="container mt-4">
            <hr style={{ width: "50%", maxWidth: "90%" }} />
          </div>
          <div className="sub-container ">
            <Link to="/about"><span className="btn dark-golden relative">Learn More</span></Link>
          </div>
        </div>
      </div>
      <div className="section sub-container">
        <div className="flex space-between wrap align-center partner-img">
          <img src={ada} alt="ada" />
          <img src={cda} alt="cda" />
          <img src={sd} alt="sd" />
        </div>
      </div>
    </>
  )
}

export default SecondSections
